import React, { useState, useEffect } from  'react';
import { Form, Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import {
  CalculatorIcon
} from '@heroicons/react/24/outline'

import MultipleFileInput from  './../finalFormFields/MultipleFileInput';
import { deleteOperationDS } from '../../dataServices/operations';
import axiosClient from '../../utils/axios';
import DateSelectField from  './../finalFormFields/DateSelectField';
import SelectInput from  './../finalFormFields/SelectInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import TextInput from  './../finalFormFields/TextInput';
import JSONValueInput from  './../finalFormFields/JSONValueInput';

const OperationTransactionForm = ({
  isCreate = true,
  onSubmit,
  initialValues,
  afterDeletion = () => {},
  handleShowDeleteModal,
  showSeason = true,
  farmlandId,
}) => {
  const dispatch = useDispatch();
  const { operationId } = useParams();
  const { t } = useTranslation();
  const operationTypes = useSelector((state) => state?.globals?.operationTypes);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [calcLoading, setCalcLoading] = useState(false);
  const [attributesList, setAttributesList] = useState([]);
  const [growingSeasons, setGrowingSeasons] = useState([]);

  useEffect(() => {
    fetchFarmlands();

    if(initialValues.operationtype) {
      onOperationTypeSelect(initialValues.operationtype);
    }
  }, []);

  const fetchFarmlands = async () => {
    await axiosClient
      .get('/se/?page_size=10000')
      .then(({ data }) => {
        setGrowingSeasons(data.results);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const onOperationTypeSelect = async (typeId) => {
    return await axiosClient
      .get(`/operations/operationattribute/?operationtype_id=${typeId}&page_size=10000`)
      .then(({ data }) => {
        setAttributesList(data);
        console.log({ operationattribute: data });
        return data;
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const onOperationAttributeSelect = async (value, values) => {
    // const shouldCalculateConsumption = values?.operationtype === '5';
    // const dropdownSelection = value?.find(v => v.operationattribute === 11)?.attribute_value?.value;

    //
    // if (!shouldCalculateConsumption) {
    //   return;
    // }
    //  //  && values.find(v => v.)
    // console.log({ value, values });
    // const consumption = await axiosClient
    //   .get(`/operations/consumption/?farmland_id=${farmlandId}&operationtype_id=${values?.operationtype}&operationattribute_id=10&dropdown_selection=${dropdownSelection}`)
    //   .then(({ data }) => {
    //     return data;
    //   })
    //   .catch(function (err) {
    //     return 'error';
    //   });
    // console.log({ consumption });
  };

  const operationdetails = attributesList.map(attr => {
    return ({
      operationattribute: attr.operationattribute_id,
      operationdetail_id: attr.operationdetail_id,
      operation: operationId,
      attribute_value: initialValues?.operationdetails?.find(a => a.operationattribute === attr.operationattribute_id)?.attribute_value || '',
    })
  });

  const handleDelete = async () => {
    await dispatch(deleteOperationDS(initialValues?.operation_id));
    setShowDeleteModal(false);
    afterDeletion();
    // navigate('/operations/');
  }

  const extractOpType = (id) => {
      const found = operationTypes.find(ot => ot.operationtype_id === parseInt(id, 2));
      return found;
  };

  const hasAnyAttributeWithConsumption = (values,id) => {
    console.log({ values });
    const attributes = operationdetails?.filter(attr => attr.operationattribute === id);


    const found = operationTypes.find(ot => ot.operationtype_id === parseInt(id, 2));
    return found;
  };



  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      initialValues={{
        ...initialValues,
        operationdetails,
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={
        ({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
          form,
        }) => {
          // const form = useForm();

          const calculateConsumption = async (values) => {
            const { operationtype, operationdetails } = values;
            console.log({ values });
            const operationAttributeId = operationdetails?.[0]?.operationattribute || operationdetails[0]?.operationattribute.operationdetail_id;
            const operationAttributeValue = operationdetails?.[0]?.attribute_value?.value;
            setCalcLoading(true);
            return await axiosClient
              .get(`/operations/consumption/?farmland_id=${farmlandId}&operationtype_id=${operationtype}&operationattribute_id=${operationAttributeId}&dropdown_selection=${operationAttributeValue}`)
              .then(({ data: { consumption } }) => {
                setCalcLoading(false);
                form.change('consumption', consumption);
              })
              .catch(function (err) {
                return 'error';
              });
          };

          const sowingDateDiff = () => {
            const { season, operationdate } = values;
            const selectedSeason = growingSeasons.find(s => parseInt(s.season_id) === parseInt(season));
            console.log({ operationdate, growingSeasons, selectedSeason });
            if (!operationdate || !season || !selectedSeason?.sowing_date) {
              return null;
            }

            let diff = moment(operationdate).diff(moment(selectedSeason?.sowing_date), 'days');

            const unit = ( Math.abs(diff) === 1) ? t('day_abr') : t('days_abr');
            return `${diff} ${unit} ${t('from sowing')}`;
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="">
                  <OnChange name="operationtype">
                    {async (value) => {
                      const list = await onOperationTypeSelect(value);
                      // list.forEach((a, i) => {
                      //   form.change(`operationdetails[${i}].attribute_value`, { value: '' });
                      // })
                    }}
                  </OnChange>

                  <OnChange name="operationdetails">
                    {async (value) => {
                      await onOperationAttributeSelect(value, values);
                    }}
                  </OnChange>

                  <Field
                    type='date'
                    component={DateSelectField}
                    label={t('Date')}
                    name="operationdate"
                    required
                    wrapperClass={"mb-0"}
                  />

                  <div className="col-12 mb-4 pt-2 text-muted">
                    {sowingDateDiff()}

                  </div>

                  {/*<Field*/}
                  {/*  component={TextInput}*/}
                  {/*  label={t('Title')}*/}
                  {/*  name="title"*/}
                  {/*  type="text"*/}
                  {/*/>*/}

                  <div className={showSeason ? '' : 'd-none'}>
                    <Field
                      component={SelectInput}
                      label={t('Growing season')}
                      name="season"
                      disabled={!!initialValues.season}
                      options={growingSeasons && growingSeasons.map(gs => ({
                        name: gs.name,
                        value: gs.season_id,
                      }))}
                      required
                    />
                  </div>

                  <Field
                    component={SelectInput}
                    label={t('Operation')}
                    name="operationtype"
                    options={operationTypes && operationTypes.map(gs => ({
                      name: gs.name,
                      value: gs.operationtype_id,
                    }))}
                    required
                  />

                  {attributesList && attributesList.length > 0 && (
                    <fieldset className="form-group my-4 p-3 bg-light">
                      <legend className="h5">{t('Operation details')}</legend>
                      <FieldArray name="operationdetails">
                        {({ fields }) => (
                          <div>
                            {fields.map((name, index) => (
                              <div key={name}>
                                <Field type="hidden" disabled name={`${name}.operation`} component="input" />
                                <Field type="hidden" disabled name={`${name}.operationattribute`} component="input" />
                                <div>
                                  <label>{attributesList[index]?.name}</label>
                                  <Field
                                    name={`${name}.attribute_value`}
                                    component={JSONValueInput}
                                    attribute={attributesList[index]}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </FieldArray>
                    </fieldset>
                  )}


                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        {extractOpType(values.operationtype)?.hasconsumption && (
                          <Field
                            type="number"
                            step={1}
                            disabled={calcLoading}
                            component={TextInput}
                            label={t('Total fuel Consumption')}
                            name="consumption"
                            suffix={t('Litre')}
                            cta={(
                              <a
                                className="mb-2 d-inline-block"
                                href="#"
                                onClick={async () => {
                                  await calculateConsumption(values);
                                }}
                              >
                                <CalculatorIcon className="me-2" height={16} />
                                {t('Calculate total consumption')}
                              </a>
                            )}
                          />
                        )}
                    </div>
                  </div>


                  <Field
                    component={TextAreaInput}
                    label={t('Comments')}
                    name="description"
                  />

                  <Field
                    component={MultipleFileInput}
                    label={t('Attachments')}
                    name="operationfiles"
                  />

                  {submitError && <div className="error">{submitError}</div>}

                  <div className="mt-5 d-flex justify-content-between">
                    {/*<button*/}
                    {/*  className="btn btn-lg btn-primary"*/}
                    {/*  onClick={() => navigate(-1)}*/}
                    {/*  type="button"*/}
                    {/*  disabled={submitting}*/}
                    {/*>*/}
                    {/*  {t('Back')}*/}
                    {/*</button>*/}

                    { !isCreate && (
                      <button
                        className="btn btn-lg btn-danger"
                        type="button"
                        onClick={handleShowDeleteModal ? handleShowDeleteModal : () => {
                          setShowDeleteModal(true);
                        }}
                        disabled={submitting}
                      >
                        {t('Delete')}
                      </button>
                    )}

                    <button
                      className="btn btn-lg btn-primary ms-auto"
                      type="submit"
                      disabled={submitting || pristine}
                    >
                      {isCreate ? t('Submit') : t('Save')}
                    </button>
                  </div>
                </div>
              </div>

              <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Operation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {'Are you sure you want to delete this operation?'}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                    Close
                  </Button>

                  <Button
                    variant="danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </form>
          );
        }
      }
    />
  );
};

export default OperationTransactionForm;
